<template>
  <div>
    <q-form ref="editForm">
      <div class="row">
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-card title="기본정보" class="cardClassDetailForm">
            <template slot="card-button">
              <q-btn-group outline>
                <c-btn
                  v-show="editable && !disabled"
                  :url="saveUrl"
                  :isSubmit="isSave"
                  :param="tbmData"
                  :mappingType="saveType"
                  label="저장"
                  icon="save"
                  @beforeAction="saveTbm"
                  @btnCallback="saveCallback"
                />
                <!-- <c-btn
                  v-show="editable&&!disabled&&isOld"
                  :url="updateUrl"
                  :isSubmit="isComplete"
                  :param="tbmData"
                  mappingType="PUT"
                  label="완료"
                  icon="check"
                  @beforeAction="resultComplete"
                  @btnCallback="saveCallback"
                /> -->
                <c-btn
                  v-show="editable && isOld && !disabled"
                  label="삭제"
                  :editable="editable"
                  icon="delete_forever"
                  @btnClicked="remove"
                />
                <c-btn
                  v-show="editable && !isOld"
                  label="타 TBM 불러오기"
                  icon="save_alt"
                  @btnClicked="copyTbm"
                />
                <c-btn
                  v-show="tbmData.tbmId"
                  label="출력"
                  icon="print"
                  @btnClicked="printTbm"
                />
                <c-qr-btn
                  v-if="editable && isOld"
                  :mobileUrl="'/sai/tbm/tbm?tbmId='+tbmData.tbmId"
                ></c-qr-btn> 
              </q-btn-group>
            </template>
            <template slot="card-detail">
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-plant
                  required
                  :editable="editable"
                  :disabled="disabled"
                  type="edit"
                  name="plantCd"
                  v-model="tbmData.plantCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-process
                  :editable="editable"
                  :disabled="disabled"
                  label="공정"
                  name="processCd"
                  v-model="tbmData.processCd"
                >
                </c-process>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-vendor
                  :editable="editable"
                  :disabled="disabled"
                  :haveProcess="true"
                  label="업체"
                  name="vendorCd"
                  v-model="tbmData.vendorCd"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-text
                  :editable="editable"
                  :disabled="true"
                  label="TBM번호"
                  name="tbmNo"
                  v-model="tbmData.tbmNo"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
                <c-datepicker
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="TBM일자"
                  type="date"
                  name="tbmDate"
                  v-model="tbmData.tbmDate"
                >
                </c-datepicker>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 work-item">
                <c-datepicker
                  :disabled="disabled"
                  :editable="editable"
                  :range="true"
                  :minuteStep="10"
                  type="time"
                  label="TBM시간"
                  name="workTime"
                  v-model="workTime"
                />
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  required
                  :editable="editable"
                  :disabled="disabled"
                  label="TBM명"
                  name="tbmName"
                  v-model="tbmData.tbmName"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="작업"
                  name="workName"
                  v-model="tbmData.workName"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-work-permit
                  :editable="editable"
                  :disabled="disabled"
                  :isRemoveConfirm="true"
                  label="작업허가번호 | 작업일"
                  name="sopWorkPermitId"
                  v-model="tbmData.sopWorkPermitId"
                  @dataChange="dataChange"
                  @remove="removeWorkPermit"
                >
                </c-work-permit> 
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-field
                  :editable="editable"
                  :disabled="disabled"
                  type="dept_job_user"
                  label="TBM리더"
                  name="tbmLeaderId"
                  v-model="tbmData.tbmLeaderId"
                />  
              </div>
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="TBM장소"
                  name="tbmLocation"
                  v-model="tbmData.tbmLocation"
                >
                </c-text>
              </div>
              <!-- tbm 리더 서명 -->
              <!-- <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1" style="position: relative; top: -20px;">
                <p class="leaderSign"> TBM 리더 서명 </p>
                <q-btn 
                  style="position: relative; top: -10px;"
                  round unelevated 
                  size="10px"
                  color="amber" 
                  icon="mode"
                  @click.stop="() => { tbmLeaderSign = tbmData.tbmLeaderSign}"
                  >
                  <q-popup-proxy ref="sign_pop" :breakpoint="400">
                    <component
                      :is="component"
                      :data="tbmData"
                      @signCallback="signCallback"
                    />
                  </q-popup-proxy>
                </q-btn>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-1 col-lg-1 col-xl-1" style="position: relative; top: -20px;">
                <q-checkbox v-model="sameDate" disable label="작업날짜와동일" 
                color="orange" left-label class="custom-checkbox" ></q-checkbox>
              </div> -->
              <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
                <c-text
                  :editable="editable"
                  :disabled="disabled"
                  label="작업내용"
                  name="tbmContents"
                  v-model="tbmData.tbmContents"
                >
                </c-text>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="작업 전 일일 안전점검 시행 결과"
                  :rows="2"
                  name="preInspectionResult"
                  v-model="tbmData.preInspectionResult"
                >
                </c-textarea>
              </div>
              <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
                <c-textarea
                  :editable="editable"
                  :disabled="disabled"
                  label="작업 후 종료 미팅 (중점대책의 실효성)"
                  :rows="2"
                  name="tbmResult"
                  v-model="tbmData.tbmResult"
                >
                </c-textarea>
              </div>
            </template>
          </c-card>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <c-table
            ref="riskTable"
            title="위험요인 목록"
            :columns="riskGrid.columns"
            :gridHeight="riskGrid.height"
            :data="tbmData.tbmRiskList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="위험요인을 추가하세요."
            :hideBottom="true"
            :editable="editable && !disabled"
            rowKey="tbmRiskId"
            selection="multiple"
            @table-data-change="tableDataChange"
          >
            <template slot="table-button">
              <q-btn-group outline> 
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="3단계판단법"
                  icon="add"
                  @btnClicked="add3rdRisk"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="4M"
                  icon="add"
                  @btnClicked="add4MRisk"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="JRA"
                  icon="add"
                  @btnClicked="addJRARisk"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="직접추가"
                  icon="add"
                  @btnClicked="addDirectRisk"
                />
                <c-btn
                  v-if="editable && !disabled && tbmData.tbmRiskList.length > 0"
                  :showLoading="false"
                  label="제외"
                  icon="remove"
                  @btnClicked="removeRisk"
                />
              </q-btn-group>
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name ==='tbmRisk'">
                <c-text-column
                  v-if="props.row['tbmRiskType'] === '직접추가'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmRisk']"
                  @datachange1="datachange1(props)"
                />
                <span v-else>
                  {{props.row['tbmRisk']}}
                </span>
              </template>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-8 col-lg-8 col-xl-8">
          <c-table
            ref="attendeeTable"
            title="참석자 및 건강상태 목록"
            :columns="attendeeGrid.columns"
            :gridHeight="attendeeGrid.height"
            :data="tbmData.tbmAttendeeList"
            :filtering="false"
            :columnSetting="false"
            :usePaging="false"
            noDataLabel="참석자가 없습니다."
            :hideBottom="true"
            :editable="editable&&!disabled"
            selection="multiple"
            rowKey="tbmAttendeeId"
          >
            <template slot="table-button">
              <q-btn-group outline>
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="추가(업체 근무자)"
                  icon="add"
                  @btnClicked="addAttendeePartner"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="추가(현업)"
                  icon="add"
                  @btnClicked="addAttendee"
                />
                <c-btn
                  v-if="editable && !disabled"
                  :showLoading="false"
                  label="직접추가"
                  icon="add"
                  @btnClicked="addAttendeeDirect"
                />
                <c-btn
                  v-if="editable && !disabled && tbmData.tbmAttendeeList.length > 0"
                  :showLoading="false"
                  label="제외"
                  icon="remove"
                  @btnClicked="removeAttendee"
                />
              </q-btn-group>
            </template>
            <template v-slot:customArea="{ props, col }">
              <template v-if="col.name === 'click' && props.row['attendeeType'] != '외부'">
                <q-btn 
                  round unelevated 
                  size="10px"
                  color="amber" 
                  icon="mode"
                  @click.stop="() => { rowIndex = props.rowIndex, electronSignature = props.row.electronSignature}"
                  >
                  <q-popup-proxy :ref="'proxy_' + props.rowIndex" :breakpoint="400">
                    <component
                      :is="col.component"
                      :outFlag="true"
                      :popupParam="props.row"
                      :data="tbmData"
                      :rowIndex="props.rowIndex"
                      @signCallback2="signCallback2"
                    />
                  </q-popup-proxy>
                </q-btn>
              </template>
              <template v-if="col.name ==='tbmAttendeeDept'">
                <c-text-column
                  v-if="props.row['attendeeType'] === '직접추가'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmAttendeeDept']"
                  @datachange1="datachange1(props)"
                />
                <span v-else>
                  {{props.row['tbmAttendeeDept']}}
                </span>
              </template>
              <template v-if="col.name ==='tbmAttendeeJob'">
                <c-text-column
                  v-if="props.row['attendeeType'] === '직접추가'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmAttendeeJob']"
                  @datachange1="datachange1(props)"
                />
                <span v-else>
                  {{props.row['tbmAttendeeJob']}}
                </span>
              </template>
              <template v-if="col.name ==='tbmAttendeeName'">
                <c-text-column
                  v-if="props.row['attendeeType'] === '직접추가'"
                  :editable="editable"
                  :props="props"
                  :col="col"
                  :disabled="disabled"
                  v-model="props.row['tbmAttendeeName']"
                  @datachange1="datachange1(props)"
                />
                <span v-else>
                  {{props.row['tbmAttendeeName']}}
                </span>
              </template>
            </template>
          </c-table>
        </div>
        <div class="col-xs-12 col-sm-12 col-md-4 col-lg-4 col-xl-4">
          <c-upload 
            style="padding-top:20px"
            :attachInfo="attachInfo"
            :editable="editable&&!disabled"
            label="교육자료, 참석자서명, 증빙사진">
          </c-upload>
        </div>
      </div>
    </q-form>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>
<script>
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
import { uid } from 'quasar';
export default {
  name: 'tbm-detail',
  props: {
    popupParam: {
      type: Object,
      default(){
        return {
          tbmId: '',
        }
      },
    },
    contentHeight: null,
  },
  data() {
    return {
      tbmData: {
        tbmId: '',
        tbmNo: '',
        plantCd: '',
        permitDate: '', // 참조 작업허가서 날짜
        workName: '', // 작업명
        tbmLeaderDept: '', // TBM리더 부서or(외부업체인 경우)회사
        tbmLeaderId: '', // TBM리더 이름
        tbmLeaderSign: '', // TBM리더 전자서명
        tbmName: "", // TBM 타이틀
        tbmDate: "", // TBM 진행 날짜
        tbmStarTime: '', // 시작시간
        tbmEndTime: '', // 종료시간
        tbmLocation: "", // 장소
        tbmContents: "", // 작업내용
        preInspectionResult: '', // 사전점검결과
        tbmResult: '', // TBM 결과 (중점대책의 실효성)
        tbmCompleteFlag: 'N', // TBM 진행단계
        processCd: "", // 공정코드
        vendorCd: "", // 협력사 코드
        permitNo: "", // 참조 작업허가서 번호
        sopWorkPermitId: '', 
        preInspections: "", // 사전점검에서 체크한 항목들. XXX,XXX,XXX 형식
        
        regUserId: '',  // 작성자 ID
        regUserName: '', 
        regUserDeptName: '',
        regDtStringTime: '',
        regDt: '',
        chgUserId: '',
        chgUserName: '',
        chgUserDeptName: '',
        chgDtStringTime: '',
        chgDt: '',
        tbmAttendeeList: [],
        tbmRiskList: [],
        deleteTbmAttendeeList: [],
        deleteTbmRiskList: [],
      },
      workTime: [], // 작업시간 (리스트타입) [시작시간 ~ 종료시간]
      attachInfo: {
        isSubmit: '',
        taskClassCd: 'TBM_EDUCATION',
        taskKey: '',
        beforeTaskKey: '',
      },
      component: () => import(`${'./signaturePopLeader.vue'}`),
      riskGrid: {
        columns: [
          {
            required: true,
            name: "tbmRisk",
            field: "tbmRisk",
            label: "위험요인",
            align: "left",
            style: "width:40%",
            type: "custom",
            sortable: false,
          },
          {
            name: "tbmImprove",
            field: "tbmImprove",
            label: "안전대책",
            type: "text",
            style: "width:40%",
            align: "left",
            sortable: false,
          },
          {
            name: "tbmRiskType",
            field: "tbmRiskType",
            label: "구분",
            style: "width:10%",
            align: "center",
            sortable: false,
          },
          {
            name: "tbmRiskKeyFlag",
            field: "tbmRiskKeyFlag",
            label: "중점요인",
            type: "check",
            true: 'Y',
            false: 'N',
            style: "width:10%",
            align: "center",
            sortable: false,
          },
        ],
        height: "250px",
        data: [],
      },
      attendeeGrid: {
        columns: [
          {
            required: true,
            name: "tbmAttendeeDept",
            field: "tbmAttendeeDept",
            label: "소속",
            style: 'width:150px',
            align: "center",
            sortable: false,
            type: 'custom'
          },
          {
            name: "tbmAttendeeJob",
            field: "tbmAttendeeJob",
            label: "직책",
            style: 'width:90px',
            align: "center",
            sortable: false,
            type: 'custom'
          },
          {
            required: true,
            name: "tbmAttendeeName",
            field: "tbmAttendeeName",
            label: "참석자",
            style: 'width:120px',
            align: "center",
            sortable: false,
            type: 'custom'
          },
          {
            name: 'mentalFlag',
            field: 'mentalFlag',
            label: '정신적<br>피로',
            style: 'width:50px', 
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'phychologicalFlag',
            field: 'phychologicalFlag',
            label: '심리적<br>피로',
            style: 'width:50px', 
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'physicalFlag',
            field: 'physicalFlag',
            label: '육체적<br>피로',
            style: 'width:50px', 
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'physicalDefectFlag',
            field: 'physicalDefectFlag',
            label: '신체적<br>결함',
            style: 'width:50px', 
            align: 'center',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
            name: 'healthStatusCd',
            field: 'healthStatusCd',
            label: '건강상태',
            type: 'select',
            setHeader: true,
            style: 'width:90px',
            align: 'center',
            codeGroupCd: 'TBM_HEALTH_STATUS_CD',
            sortable: false,
          },
          {
            name: 'click',
            field: 'click',
            label: '서명<br>',
            style: 'width:60px',
            type: 'custom',
            align: 'center',
            sortable: false,
            visible: true,
            component: () => import(`${'./signaturePop.vue'}`),
          },
          {
            name: 'remark',
            field: 'remark',
            label: '비고',
            type: 'textarea',
            style: 'width:200px',
            align: 'left',
            sortable: false,
          },
        ],
        height: "300px",
        data: [],
      },
      getUrl: "",
      saveUrl: '',
      insertUrl: "",
      deleteUrl: "",
      updateUrl: "",
      printUrl: "",
      getWorkPermitUrl: '',
      saveType: "POST",
      isSave: false,
      isComplete: false,
      isEdit: false,
      popupOptions: {
        target: null,
        title: "",
        visible: false,
        // isFull: true,
        top: "",
        param: {},
        closeCallback: null,
      },
      saveCall: {
        saveCallData: '',
      },
      editable: false,
      serverName: '',
    };
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  computed: {
    disabled() { return this.tbmData.tbmCompleteFlag === "Y" },
    sameDate() { return this.tbmData.permitDate === this.tbmData.tbmDate },
    isOld() { return Boolean(this.popupParam.tbmId) }
  },
  watch: {
  },
  methods: {
    init() {
      this.editable = this.$route.meta.editable;
      this.getWorkPermitUrl = selectConfig.sop.swp.workPermit.get.url;
      this.getUrl = selectConfig.sai.tbm.get.url;
      this.saveUrl = transactionConfig.sai.tbm.insert.url;
      this.insertUrl = transactionConfig.sai.tbm.insert.url;
      this.updateUrl = transactionConfig.sai.tbm.update.url;
      this.deleteUrl = transactionConfig.sai.tbm.delete.url;
      this.printUrl = selectConfig.sai.tbm.print.url;
      this.getDetail();
    },
    getDetail() {
      if (this.popupParam.tbmId) {
        this.$http.url = this.$format(this.getUrl, this.popupParam.tbmId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.tbmData = this.$_.clone(_result.data);
          // 상세팝업 최초작성/최근수정 정보 노출
          this.$emit('setRegInfo', _result.data)
          this.$set(this.attachInfo, 'taskKey', this.popupParam.tbmId);
          this.workTime = [_result.data.tbmStartTime, _result.data.tbmEndTime]
          this.tbmData.tbmLeaderId = _result.data.tbmLeaderId
        },);
      } else { this.tbmData.tbmDate = this.$comm.getToday()
        this.workTime = ['08:00', '09:00'];}
    },
    saveTbm() {
      if (this.tbmData.tbmId) {
        this.saveUrl = this.updateUrl;
        this.saveType = "PUT";
      } else {
        this.saveUrl = this.insertUrl;
        this.saveType = "POST";
      }
      this.tbmData.tbmStartTime = this.workTime[0]
      this.tbmData.tbmEndTime = this.workTime[1]

      this.$refs["editForm"].validate().then((_result) => {
        if (_result && this.$comm.validTable(this.riskGrid.columns, this.tbmData.tbmRiskList)
          && this.$comm.validTable(this.attendeeGrid.columns, this.tbmData.tbmAttendeeList)) {
          window.getApp.$emit("CONFIRM", {
            title: "확인",
            message: "저장하시겠습니까?",
            type: "info", // success / info / warning / error
            // 확인 callback 함수
            confirmCallback: () => {
              if (this.tbmData.tbmId){this.tbmData.chgUserId = this.$store.getters.user.userId;
              } else {this.tbmData.regUserId = this.$store.getters.user.userId;}
              this.isSave = !this.isSave;
            },
            // 취소 callback 함수
            cancelCallback: () => {},
          });
        } else {
          window.getApp.$emit("APP_VALID_ERROR");
        }
      });
    },
    saveCallback(_result) {
      window.getApp.$emit("APP_REQUEST_SUCCESS");
      this.popupParam.tbmId = _result.data
      if (this.saveType === "POST") { this.$set(this.attachInfo, 'isSubmit', uid())}
      this.$set(this.attachInfo, 'taskKey', _result.data)
      this.$set(this.attachInfo, 'isSubmit', uid())
      this.getDetail();
    },
    resultComplete() {
      this.$refs["editForm"].validate().then((_result) => {
        if (_result) {
          if (this.$comm.validTable(this.riskGrid.columns, this.tbmData.tbmRiskList)) {
            window.getApp.$emit("CONFIRM", {
              title: "확인",
              message: "완료하시겠습니까? (저장과 함께 처리됩니다.)",
              type: "warning", // success / info / warning / error
              confirmCallback: () => { 
                this.tbmData.tbmCompleteFlag = 'Y'
                this.tbmData.chgUserId = this.$store.getters.user.userId;
                this.isComplete = !this.isComplete;
              },
              cancelCallback: () => {}, // 취소 callback 함수
            });
          }
        } else {window.getApp.$emit("APP_VALID_ERROR")}
      });
    },
    addDirectRisk() {
      this.tbmData.tbmRiskList.push({
        tbmId: this.tbmData.tbmId,
        tbmRiskId: uid(),
        tbmRiskProcess: "",
        tbmRisk: "",
        tbmImprove: "",
        tbmRiskKeyFlag: 'N',
        tbmRiskType: '직접추가',
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      });
    },
    removeRisk() {
      let selectData = this.$refs["riskTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", 
          message: "선택된 항목이 없습니다.", 
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.tbmData.deleteTbmRiskList) {this.tbmData.deleteTbmRiskList = [];}
          if (this.$_.findIndex(this.tbmData.deleteTbmRiskList, {
              tbmRiskId: item.tbmRiskId}) === -1 && item.editFlag !== "C"
          ) {this.tbmData.deleteTbmRiskList.push(item);}
          this.tbmData.tbmRiskList = this.$_.reject(this.tbmData.tbmRiskList, item);
        });
      }
    },
    add4MRisk() {
      this.popupOptions.title = '4M';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.tbmData.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskRegister/fmAssessRiskRegisterPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAssessRiskRegisterPopPopup1;
    },
    addJRARisk() {
      this.popupOptions.title = 'JRA';
      this.popupOptions.param = {
        type: 'multiple',
        plantCd: this.tbmData.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskRegister/jraAssessRiskRegisterPop.vue'}`);
      this.popupOptions.width = '80%';
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAssessRiskRegisterPopPopup2;
    },
    closeAssessRiskRegisterPopPopup1(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          this.tbmData.tbmRiskList.splice(0, 0, {
            tbmId: this.tbmData.tbmId,
            tbmRiskId: uid(), 
            tbmJobStep: item.jobName, // 작업단계가 없기 때문에 작업으로 <<
            tbmRisk: item.riskHazardName,
            tbmImprove: item.addingRiskManagementActivities, // 안전대책은 추가 리스트관리 계획
            tbmRiskKeyFlag: 'N',
            tbmRiskType: '4M',
            regUserId: this.$store.getters.user.userId,
            editFlag: "C",
          });
        })
      }
    },
    closeAssessRiskRegisterPopPopup2(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, item => {
          this.tbmData.tbmRiskList.splice(0, 0, {
            tbmId: this.tbmData.tbmId,
            tbmRiskId: uid(), 
            tbmJobStep: item.jobStepName, 
            tbmRisk: item.riskHazardName,
            tbmImprove: item.currentSafetyMeasures, // 안전대책은 현재안전조치
            tbmRiskKeyFlag: 'N',
            tbmRiskType: 'JRA',
            regUserId: this.$store.getters.user.userId,
            editFlag: "C",
          });
        })
      }
    },
    // TBM 참석자 선택
    addAttendeePartner() {
      this.popupOptions.title = "업체 근무자 검색"; // 업체 근무자 검색
      this.popupOptions.param = {
        type: "multiple",
        plantCd: this.tbmData.plantCd,
        vendorCd: this.tbmData.vendorCd // 수급업체코드
      };
      this.popupOptions.target = () => import(`${"@/pages/common/vendor/vendorUserPopTbm.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAddPartner;
    },
    closeAddPartner(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tbmData.tbmAttendeeList, {
            tbmAttendeeUserId: _item.userId});
          if (index === -1) {
            this.tbmData.tbmAttendeeList.push({
              tbmId: this.popupParam.tbmId,
              tbmAttendeeId: uid(),
              tbmAttendeeDept: '(업체) ' + _item.vendorName,  // 소속명
              vendorCd: _item.vendorCd,
              tbmAttendeeUserId: "",
              tbmAttendeeName: _item.personName,
              tbmAttendeeJob: _item.jobName,
              healthStatusCd: "THS0000001",
              mentalFlag: "N",
              phychologicalFlag: "N",
              physicalFlag: "N",
              physicalDefectFlag: "N",
              attendeeType: '파트너',
              remark: "",
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
    addAttendee() {
      this.popupOptions.title = "TBM 참석자 검색"; 
      this.popupOptions.param = {
        type: "multiple",
        plantCd: this.tbmData.plantCd,
      };
      this.popupOptions.target = () => import(`${"@/pages/common/user/userPop.vue"}`);
      this.popupOptions.width = "70%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeAttendee;
    },
    closeAttendee(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          let index = this.$_.findIndex(this.tbmData.tbmAttendeeList, {
            tbmAttendeeUserId: _item.userId,
          });
          if (index === -1) {
            this.tbmData.tbmAttendeeList.push({
              tbmId: this.popupParam.tbmId,
              tbmAttendeeId: uid(),
              tbmAttendeeDept: '(현업) ' + _item.deptName,  // 소속명
              tbmAttendeeUserId: _item.userId,
              tbmAttendeeName: _item.userName,
              tbmAttendeeJob: _item.jobName,
              healthStatusCd: "THS0000001",
              mentalFlag: "N",
              phychologicalFlag: "N",
              physicalFlag: "N",
              physicalDefectFlag: "N",
              attendeeType: '내부',
              remark: "",
              regUserId: this.$store.getters.user.userId,
              editFlag: "C",
            })
          }
        })
      }
    },
    addAttendeeDirect() {
      // 참석자 직접추가
      const newAttendee = {
        tbmId: this.popupParam.tbmId,
        tbmAttendeeId: uid(),
        tbmAttendeeUserId: "",
        tbmAttendeeName: "",
        tbmAttendeeJob: "",
        tbmAttendeeDept: '',  
        healthStatusCd: "THS0000001",
        mentalFlag: "N",
        phychologicalFlag: "N",
        physicalFlag: "N",
        physicalDefectFlag: "N",
        attendeeType: '직접추가',
        remark: "",
        regUserId: this.$store.getters.user.userId,
        editFlag: "C",
      }
      this.tbmData.tbmAttendeeList.push(newAttendee);
    },
    removeAttendee() {
      let selectData = this.$refs["attendeeTable"].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit("ALERT", {
          title: "안내", 
          message: "선택된 항목이 없습니다.", // 
          type: "warning", // success / info / warning / error
        });
      } else {
        this.$_.forEach(selectData, (item) => {
          if (!this.tbmData.deleteTbmAttendeeList) 
            {this.tbmData.deleteTbmAttendeeList = [];}
          if (this.$_.findIndex(this.tbmData.deleteTbmAttendeeList, {
              tbmAttendeeId: item.tbmAttendeeId}) === -1 && item.editFlag !== "C") 
            {this.tbmData.deleteTbmAttendeeList.push(item);}
          this.tbmData.tbmAttendeeList = this.$_.reject(this.tbmData.tbmAttendeeList, item);
        });
      }
    },
    copyTbm() {
      if(this.tbmData.sopWorkPermitId) {
        window.getApp.$emit("CONFIRM", {
          title: "확인",
          message: "불러온 작업허가서 정보를 삭제하시겠습니까?",
          type: "warning", // success / info / warning / error
          confirmCallback: () => { 
            this.removeWorkPermit()
            this.callTbm()
          },
          cancelCallback: () => {}, // 취소 callback 함수
        });
      } else this.callTbm()
    },
    callTbm(){
      this.popupOptions.title = "타 TBM 검색"; // SOP 검색
      this.popupOptions.param = {
        type: "single",
      };
      this.popupOptions.target = () => import(`${"./tbmPop.vue"}`);
      this.popupOptions.width = "90%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.closeCopyTbmPopup;
    },
    closeCopyTbmPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      this.tbmData.tbmRiskList = [];
      if (data && data.length > 0) {
        if (data[0].tbmId) {
          this.$http.url = this.$format(this.getUrl, data[0].tbmId);
          this.$http.type = "GET";
          this.$http.request((_result) => {
            this.tbmData.workName = _result.data.workName;
            this.tbmData.plantCd = _result.data.plantCd;
            this.tbmData.processCd = _result.data.processCd;
            this.tbmData.tbmDate = _result.data.tbmDate
            this.tbmData.permitDate = _result.data.tbmDate
            this.tbmData.tbmLocation = _result.data.tbmLocation;
            this.tbmData.tbmName = _result.data.tbmName;
            this.tbmData.tbmContents = _result.data.tbmContents;
            this.tbmData.tbmCompleteFlag = "N";
            this.tbmData.vendorCd = _result.data.vendorCd;
            this.tbmData.tbmLeaderDept = _result.data.tbmLeaderDept
            this.tbmData.tbmLeaderId = _result.data.tbmLeaderId
            this.tbmData.tbmLeaderSign = _result.data.tbmLeaderSign
            this.tbmData.preInspections = _result.data.preInspections;
            this.tbmData.tbmAttendeeList = _result.data.tbmAttendeeList;
            this.tbmData.tbmRiskList = _result.data.tbmRiskList;
          });
        }
      }
    },
    printTbm() {
      let thisVue = this;
      this.$http.url = this.$format(this.printUrl, this.tbmData.tbmId);
      this.$http.type = "GET";
      this.$http.request((_result) => {
        console.log('출력 요청 응답값 : ', _result.data)
        let fileOrgNm = 'TBM_'+this.tbmData.tbmName +'_'+ this.tbmData.tbmDate + ".xlsx";
        let blob = thisVue.$comm.base64ToBlob(_result.data);
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, fileOrgNm);
        } else {
          let link = document.createElement("a");
          link.href = window.URL.createObjectURL(blob);
          link.download = fileOrgNm;
          link.click();
        }
      }, () => {} );
    },
    dataChange(data) {
      if (data) {
        this.$http.url = this.$format(this.getWorkPermitUrl, data.sopWorkPermitId);
        this.$http.type = 'GET';
        this.$http.request((_result) => {
          this.tbmData.sopWorkPermitId = _result.data.sopWorkPermitId // 허가번호
          this.tbmData.tbmDate = _result.data.permitDate // 작업일
          this.tbmData.permitDate = _result.data.permitDate
          this.tbmData.processCd = _result.data.processCd // 공정
          this.tbmData.vendorCd = _result.data.vendorCd // 업체
          this.tbmData.tbmLocation = _result.data.workLocation // 작업장소
          this.tbmData.workName = _result.data.sopName // 작업
          this.tbmData.tbmName = _result.data.sopName // TBM명
          this.tbmData.tbmContents = _result.data.workSummary // 작업내용
          
          // 들고온 작업허가서에 책임자가 없으면 디폴트값으로 작성자 id 자동입력
          this.tbmData.tbmLeaderId = _result.data.workManagerId || this.$store.getters.user.userId

          this.$_.forEach(this.tbmData.tbmRiskList, (item) => {
            if (!this.tbmData.deleteTbmRiskList) {this.tbmData.deleteTbmRiskList = [];}
            if (this.$_.findIndex(this.tbmData.deleteTbmRiskList, {tbmRiskId: item.tbmRiskId,}) === -1 && item.editFlag !== "C"
            ) {this.tbmData.deleteTbmRiskList.push(item);}
            this.tbmData.tbmRiskList = this.$_.reject(this.tbmData.tbmRiskList, item);
          });
          this.tbmData.tbmRiskList = [];
          this.tbmData.deleteTbmAttendeeList = this.$_.filter(this.tbmData.tbmAttendeeList, tbmAttendee => {
            return tbmAttendee.editFlag !== 'C'
              && this.$_.findIndex(this.tbmData.deleteTbmAttendeeList, { tbmAttendeeId: tbmAttendee.tbmAttendeeId }) === -1
          })
          this.tbmData.tbmAttendeeList = [];
          if (_result.data.workers && _result.data.workers.length > 0) {
            this.$_.forEach(_result.data.workers, _item => {
              let index = this.$_.findIndex(this.tbmData.tbmAttendeeList, {
                tbmAttendeeName: _item.workerName, 
              });
              if (index === -1) {
                let attendType = '직접추가'
                if (_item.belongName.includes('현업')) { attendType = '내부'} 
                else if (_item.belongName.includes('협력업체')) {attendType = '파트너'}
                this.tbmData.tbmAttendeeList.push({
                  tbmId: this.tbmData.tbmId,
                  tbmAttendeeId: uid(),
                  tbmAttendeeUserId: _item.userId,
                  tbmAttendeeName: _item.workerName,
                  tbmAttendeeDept: _item.belongName,
                  tbmAttendeeJob: _item.jobName,
                  healthStatusCd: "THS0000001",
                  mentalFlag: "N",
                  phychologicalFlag: "N",
                  physicalFlag: "N",
                  physicalDefectFlag: "N",
                  attendeeType: attendType,
                  regUserId: this.$store.getters.user.userId,
                  editFlag: "C",
                })
              }
            })
          } 
          if (_result.data.assessments && _result.data.assessments.length > 0) {
            this.$_.forEach(_result.data.assessments, _item => {
              if (!_item.riskHazardName) return
              this.tbmData.tbmRiskList.push({
                tbmId: this.tbmData.tbmId,
                tbmRiskId: uid(),
                tbmRiskProcess: _item.jobStepName,
                tbmRisk: _item.riskHazardName,
                tbmImprove: _item.safetyActionMeasures,
                tbmRiskKeyFlag: "N",
                tbmRiskType: '작업허가서',
                regUserId: this.$store.getters.user.userId,
                editFlag: "C",
              })
            })
          } 
        },);
      }
    },
    removeWorkPermit() {
      this.tbmData.sopWorkPermitId = '';
      this.tbmData.processCd = '';
      this.tbmData.workName = '';
      this.tbmData.vendorCd = '';
      this.tbmData.vendorName = '';
      this.tbmData.tbmDate = '';
      this.tbmData.tbmLeaderDept = '';
      this.tbmData.tbmLeaderId = '';
      this.tbmData.tbmLeaderSign = ''
      this.tbmData.tbmLocation = '';
      this.tbmData.tbmContents = '';
      this.tbmData.tbmStarTime = ''
      this.tbmData.tbmEndTime = ''
      this.tbmData.tbmAttendeeList = []
      this.tbmData.tbmRiskList = []
    },
    remove() {
      window.getApp.$emit('CONFIRM', {
        title: '확인',
        message: '삭제하시겠습니까?',
        type: 'info', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.$format(this.deleteUrl, this.tbmData.tbmId);
          this.$http.type = 'DELETE';
          this.$http.request(() => {
            window.getApp.$emit('APP_REQUEST_SUCCESS');
            this.$emit('closePopup')
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
    },
    add3rdRisk() {
      this.popupOptions.title = "3단계 판단법 검색"; 
      this.popupOptions.param = {
        type: "multiple",
        plantCd: this.tbmData.plantCd,
      };
      this.popupOptions.target = () => import(`${'@/pages/ram/riskRegister/3rdRamListPop.vue'}`);
      this.popupOptions.width = "80%";
      this.popupOptions.visible = true;
      this.popupOptions.closeCallback = this.close3rdPopup
    },
    close3rdPopup(data) {
      this.popupOptions.target = null;
      this.popupOptions.visible = false;
      if (data && data.length > 0) {
        this.$_.forEach(data, _item => {
          this.tbmData.tbmRiskList.push({
            tbmId: this.tbmData.tbmId,
            tbmRiskId: uid(),
            tbmRiskProcess: _item.processName,
            tbmRisk: _item.riskHazardQuestionName,
            tbmImprove: _item.improvementMeasures,
            ramAfterRiskThirdStandardId: _item.ramAfterRiskLevelCd,
            tbmRiskKeyFlag: 'N',
            tbmRiskType: '3단계판단법',
            regUserId: this.$store.getters.user.userId,
            editFlag: "C",
          });
        })
      }
    },
    signCallback(data) { 
      this.tbmData.tbmLeaderSign = data
      this.$refs["sign_pop"].hide();
    },
    signCallback2(data, rowIndex) {
      this.tbmData.tbmAttendeeList[rowIndex].electronSignature = data
      if(this.tbmData.tbmAttendeeList[rowIndex].editFlag !== 'C') {
        this.tbmData.tbmAttendeeList[rowIndex].editFlag = 'U'
      }
      let refName = 'proxy_' + rowIndex;
      this.$refs[refName].hide();
    },
    tableDataChange(prop, col){
      if (col.name === 'tbmRiskKeyFlag'){
        this.tbmData.tbmRiskList.forEach((item, index)=>{
          // if (index === checkIndex){this.$set(item, 'tbmRiskKeyFlag', 'Y')}
          if (index !== prop.rowIndex){this.$set(item, 'tbmRiskKeyFlag', 'N')}
        })
      }
    },
  }
};
</script>
<style lang="sass">
.leaderSign
  color: #0072c6 !important
  font-size: 0.9em !important
  font-weight: 600
.custom-checkbox 
  display: flex !important
  flex-direction: column-reverse !important
  align-items: flex-start !important
  .q-checkbox__label
    color: #0072c6 
    font-weight: 600
    display: block !important
  .q-checkbox__inner
    display: block !important
</style>